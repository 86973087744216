import { Player } from './Millicast_player';
import { Director, Logger, View } from '@millicast/sdk';





let millicastView = null;
let isSubscribed = false;
let autoReconnect = 'true';

const newViewer = (video, streamName, streamAccountId) => {
    const tokenGenerator = () => Director.getSubscriber({
        streamName,
        streamAccountId
    })

    const player = new Player({
        player: video
    });

    const millicastView = new View(streamName, tokenGenerator, video, autoReconnect)
    millicastView.on("broadcastEvent", (event) => {
        console.log(event);
        if(event.name === 'active' && player.isPaused()){
            if(userHasInteracted && !userPausedVideo && video.dataset.autoplay==1){
                player.showControls();
                setTimeout(()=>{
                    player.play();
                },200)
            } else {
                player.renderPlayBigButton();
            }
        }
    })

    return millicastView;
}

const subscribe = async () => {

    let video = document.querySelector('#millicast');
    let streamName = video.dataset.streamname;
    let streamAccountId = video.dataset.streamaccountid;

    //Logger.setLevel(Logger.DEBUG);
    
    if(millicastView?.isActive() || isSubscribed){
        return
    }

    try {
        isSubscribed = true
        let pinnedSourceId = null;
        const options = {
            pinnedSourceId,
            disableVideo: false,
            disableAudio: false,
            absCaptureTime: true,
            dtx: true,
        };
        window.millicastView = millicastView = newViewer(video, streamName, streamAccountId)
       await millicastView.connect(options);

        let player = new Player({
            player: video
        });

        
       if(userHasInteracted && player.isPaused() && video.dataset.autoplay==1){
           player.play();
           player.showControls();
           
       } else if(player.isPaused()) {
           player.renderPlayBigButton();
       } else {
           player.showControls();
       }

       
    } catch (error) {
        if(!autoReconnect) return;
        millicastView.reconnect()
    }
}

export const addPauseListenerToVideo = (player) => {
    player.addEventListener('pause', function(){
        userPausedVideo = true;
    })
}





window.subscribe = subscribe;
export {subscribe}